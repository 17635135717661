<template>
  <div class="buyOrderRef">
    <div class="top">
      <span class="text">退货/退款</span>
    </div>
    <div class="ms">选择退货/退款商品</div>
    <div class="list">
      <div class="item" v-for="item in orderData" :key="item.id">
        <el-checkbox-group v-model="checkList">
          <div class="left">
            <el-checkbox :label="item.id">&emsp;</el-checkbox>
          </div>
        </el-checkbox-group>
        <div class="right">
          <div class="title">
            <div>
              {{ item.shop_name }}
              <el-button
                size="mini"
                style="margin-left: 10px"
                plain
                @click="toStore(item.shop_id)"
                >查看商店</el-button
              >
            </div>
            <div>订单编号：{{ item.order_no }}</div>
          </div>
          <div class="content">
            <div>
              <el-image
                style="width: 80px; height: 80px"
                :src="item.image"
                fit="fit"
              ></el-image>
            </div>
            <div>
              <div style="padding-right: 100px; font-size: 14px">
                {{ item.title
                }}{{ item.difference ? ",[" + item.difference + "]" : "" }}
              </div>
              <div style="padding-top: 30px">x{{ item.number }}</div>
            </div>
            <div>RM{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hj" style="border: none">
      <div class="text1" style="padding: 10px">商品金额</div>
      <div
        class="text2"
        style="color: #b0b0b0; padding: 10px 30px; font-size: 14px"
      >
        RM{{ totalPrice }}
      </div>
    </div>
    <div class="hj" style="border: none">
      <div class="text1" style="padding: 10px">运费</div>
      <div
        class="text2"
        style="color: #b0b0b0; padding: 10px 30px; font-size: 14px"
      >
        RM{{ freight_price }}
      </div>
    </div>
    <div class="hj">
      <div class="text1" style="padding: 10px; font-size: 14px; color: #000">
        订单总额
      </div>
      <div
        class="text2"
        style="color: #ee4d2d; padding: 10px 30px; font-size: 20px"
      >
        RM{{ goodsTotalPrice }}
      </div>
    </div>
    <div class="hj">
      <div class="text1" style="font-size: 16px; color: #000">退款方式</div>
      <div class="text2" style="font-size: 16px; color: #ee4d2d">退回余额</div>
    </div>
    <div class="form">
      <div class="tops">
        <div style="margin-right: 20px">选择类型：</div>
        <div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">仅退款</el-radio>
            <el-radio :label="1">退货和退款</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <div>
            <span style="color: #ee4d2d">*</span>
            选择原因：
          </div>
          <div>
            <el-select
              style="min-width: 300px"
              size="small"
              v-model="value"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, i) in options"
                :key="i"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="item">
          <div><span style="color: #ee4d2d">*</span>填写理由：</div>
          <div>
            <el-input
              style="min-width: 300px"
              v-model="input"
              placeholder="请输入内容"
              type="textarea"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div><span style="color: #ee4d2d">*</span>上传图片：</div>
          <div>
            <UploadFiles3
              :ids="ids"
              :imgLength="6"
              :imageUrl="image"
            ></UploadFiles3>
          </div>
        </div>
        <div style="margin-top: 20px">
          <el-button size="medium" type="primary" @click="handleRef"
            >确认</el-button
          >
          <el-button size="medium" plain @click="toBack">取消</el-button>
        </div>

        <div class="caidai" style="top: 0">
          <div v-for="item in 10" :key="item" style="display: flex">
            <div class="red"></div>
            <div class="blue"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadFiles3 from "@/components/UploadFiles3.vue";
import { getRefundOrder, addApply } from "@/api/buy.js";
export default {
  components: {
    UploadFiles3,
  },
  data() {
    return {
      checkList: [],
      radio: 0,
      input: "",
      options: [
        "未收到货物",
        "卖家发错货",
        "货物损坏或有瑕疵",
        "产品不完整",
        "收到假冒伪劣商品",
        "产品与描述严重不符",
        "商品有使用痕迹",
      ],
      value: "",
      image: [],
      ids: [],
      orderData: [],
      goodsTotalPrice: 0,
      totalPrice: 0,
      freight_price: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getRefundOrder({
        order_id: sessionStorage.ORDERID,
      }).then((res) => {
        if (res.code == 1) {
     
          this.orderData = res.data;
          this.orderData.forEach((item) => {
            this.checkList.push(item.id);
            this.goodsTotalPrice = (
              parseFloat(this.goodsTotalPrice) +
              parseFloat(item.actual_payment) +
              parseFloat(item.freight_price)
            ).toFixed(2);
            this.totalPrice = (
              parseFloat(this.totalPrice) + parseFloat(item.actual_payment)
            ).toFixed(2);
            this.freight_price = (
              parseFloat(this.freight_price) + parseFloat(item.freight_price)
            ).toFixed(2);
          });
        }
      });
    },
    handleRef() {
      if (this.value == "") {
        this.$message.error("请选择原因");
        return false;
      }
      if (this.input == "") {
        this.$message.error("理由不能为空");
        return false;
      }
      if (this.ids.length == 0) {
        this.$message.error("请上传图片");
        return false;
      }
      let goods = [];
      let data = {
        order_id: sessionStorage.ORDERID,
        type: this.radio,
        express_type: this.value == "未收到货物" ? 0 : 1,
        reason: this.value,
        content: this.input,
        images: this.ids.join(","),
      };
      this.orderData.forEach((item) => {
        this.checkList.forEach((val) => {
          if (item.id == val) {
            goods.push({
              id: item.id,
              price:
                parseFloat(item.actual_payment) +
                parseFloat(item.freight_price),
            });
          }
        });
      });
      addApply({
        goods: goods,
        data: data,
      }).then((res) => {
        if (res.code == 1) {
        
          this.$message.success(
            "申请" + (this.radio ? "退货退款" : "退款") + "成功"
          );
          this.$router.go(-1);
        }
      });
    },
    toStore(id) {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: id,
          // addTime: this.infoData.shop.join_time,
          // onlineTime: this.infoData.shop.online_time,
        },
      });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.buyOrderRef {
  padding: 20px;
  .top {
    padding: 20px;
    background: #fff;
    border-bottom: 1px dashed #eee;
    border-radius: 6px;
    .text {
      border-bottom: 2px solid #ee4d2d;
      padding: 0 20px 16px;
    }
  }
  .ms {
    padding: 20px;
    background: #fffcf5;
    border-bottom: 1px dashed #eee;
    font-size: 14px;
    border-radius: 6px;
  }
  .list {
    .item {
      padding: 0 20px;
      display: flex;
      background: #fff;
      border-bottom: 1px dashed #eee;
      border-radius: 6px;
      align-items: center;
      .left {
        width: 5%;
      }
      .right {
        width: 95%;
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 0;
          border-bottom: 1px solid #eee;
          font-size: 14px;
        }
        .content {
          padding: 10px 0 16px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .hj {
    background: #fafafa;

    border-bottom: 1px solid #eee;
    border-radius: 6px;
    display: flex;
    justify-content: right;
    font-size: 14px;
    align-items: center;
    .text1 {
      color: #b0b0b0;
      border-right: 1px dashed #eee;
      padding: 16px 10px;
    }
    .text2 {
      padding: 16px 30px;
      color: #b0b0b0;
      min-width: 300px;
      text-align: right;
    }
  }
  .form {
    margin-top: 10px;
    font-size: 14px;
    .tops {
      padding: 20px;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 6px;
    }
    .bottom {
      position: relative;
      background: #fff;
      padding: 20px;
      border-radius: 6px;
      .item {
        padding: 10px 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>